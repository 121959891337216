<template>
  <b-modal
    id="modal-merchant-payment-request"
    ref="modal"
    v-model="open"
    size="md"
    no-close-on-backdrop
    hide-footer
    title="PAYMENT REQUEST"
  >
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-table
        :per-page="perPage"
        :current-page="page"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        hover
        responsive
        striped
      />
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal,
  BOverlay,
  VBModal,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
// import { RepositoryFactory } from '@/repository/RepositoryFactory'

// const MerchantRepository = RepositoryFactory.get('merchant')

function initialState() {
  return {
    open: false,
    invoiceId: null,
    perPage: 20,
    pageOptions: [3, 5, 10],
    page: 1,
    meta: {},
    dir: false,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    loading: false,
    fields: [
      {
        key: 'request_by',
        label: 'Request By',
        sortable: true,
      },
      {
        key: 'remark',
        label: 'Remark',
        sortable: true,
      },
      {
        key: 'request_date',
        label: 'Request Date',
      },
    ],
    items: [],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BModal,
    BOverlay,
    BTable,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(invoiceId) {
      Object.assign(this.$data, initialState())
      this.loading = true
      this.open = true
      this.invoiceId = invoiceId
      this.loading = false
    },
  },
}
</script>
<style scoped>
</style>
